import React, { useState, useEffect } from 'react'
const Timer = (props) => {

    const [seconds, setSeconds] = useState(10);
    const [isActive, setIsActive] = useState(false);
    const [msg, setMsg] = useState("")

    function toggle() {
        setIsActive(!isActive);
    }

    useEffect(()=>
    {
        setSeconds(props.startAt)
        setIsActive(true)
    }, [props])

    useEffect(()=>
    {
        setMsg(props.msg.replace('[time]',seconds))
    },[seconds]);

    useEffect(() => {
        if(seconds === 0)
        {
            props.launch()
        }
        else
        {
            let interval = null;
            if (isActive) {
            interval = setInterval(() => {
                setSeconds(seconds => seconds - 1);
            }, 1000);
            } else if (!isActive && seconds === 0) {
            clearInterval(interval);
            }
            return () => clearInterval(interval);
        }
    }, [isActive, seconds]);

    return (<div className="timer"><p>{msg}</p></div>)
}

export default Timer