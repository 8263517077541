import React, { useState, useEffect } from "react";
import socketIOClient from "socket.io-client";
import logo from '../logo.svg';
import Timer from './Timer'
import '../styles/App.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserSecret, faSearch } from '@fortawesome/free-solid-svg-icons'
//const ENDPOINT = "http://127.0.0.1:4001";
const ENDPOINT = "https://korogames.online";

function App() {
  const [player, setPlayer] = useState("");
  const [socket, setSocket] = useState();
  const [changing, setChanging] = useState(false);
  const [newUsername, setNewUsername] = useState("");
  const [isInit, setIsInit] = useState(true)
  const [game, setGame] = useState({cards: [], players: [], teamTurn: 'unset', state: 'off'})
  const [isOver, setIsOver] = useState(false)
  const [winner, setWinner] = useState('nowinner')
  const [blueScore, setBlueScore] = useState(-1)
  const [redScore, setRedScore] = useState(-1)

  useEffect(() =>
  {
    const s = socketIOClient(ENDPOINT);
    console.log(s)
    setSocket(s)
    s.on("playerInit", datas => {
      setGame(datas.game)
      if(localStorage.getItem('player')!==null)
      {
        let oldPlayer = JSON.parse(localStorage.getItem('player'))
        oldPlayer.id = datas.player.id
        oldPlayer.team = datas.game.state==='on'?oldPlayer.team:"noteam"
        oldPlayer.ready = datas.game.state==='on'
        oldPlayer.isSpy = datas.game.state==='on'?oldPlayer.isSpy:false
        oldPlayer.userName = oldPlayer.userName==undefined?datas.player.userName:oldPlayer.userName
        s.emit("playerLoadStorage", oldPlayer)
        setPlayer(oldPlayer)
      }
      else
      {
        setPlayer(datas.player)
      }
    });
    s.on("cardTurn", datas=>
    {
      setGame(datas.game)
      if(datas.player.team!==datas.card.color)
      {
        if(isInit) setIsInit(false)
      }
    })
    s.on("gameOver", datas=>
    {
      setIsOver(true)
      setWinner(datas.winner)
      setGame(datas.game)
    })
    s.on("gameUpdate", game=>
    {
      setGame(game)
    })
  }, []);

  useEffect(() =>
  {
    if(socket!==undefined)
    {
      localStorage.setItem('player', JSON.stringify(player))
      console.log(player)
      socket.emit("playerUpdate", player)
      socket.on("gameStart", game=>
      {
        setGame(game)
        console.log(player)

        setPlayer(game.players.filter(user => user.userName==player.userName )[0])
      })
    }
  }, [player])

  useEffect(() =>
  {
    if(socket!==undefined)
    {
      socket.emit("gameUpdate", game)
    }
  }, [game])

  useEffect(()=>
  {
    if(changing)
    document.getElementById("nameInput").focus()
  },[changing])

  const cardClick = (cardId)=>
  {
    if(player.team == game.teamTurn && !player.isSpy)
    {
      let datas = {player : player, cardId : cardId}
      socket.emit("cardClick", datas)
    }
  }


  const changeTeamPlayer = (team,isSpy)=>
  {
    setPlayer({...player, team:team, isSpy:isSpy, ready:false})
  }

  const changeRolePlayer = ()=>
  {
    setPlayer({...player, isSpy: !player.isSpy, ready:false})
  }

  const playerPass = ()=>
  {
    socket.emit("playerPass", player)
  }

  const changeReadyPlayer = ()=>
  {
    setPlayer({...player, ready: (!player.ready) })
  }

  const stopTheGame = ()=>
  {
    setIsOver(false)
    setWinner('nowinner')
    setPlayer({...player, team: "noteam", ready: false})
    socket.emit("gameStop")
  }

  const startTheGame = ()=>
  {
    console.log(player)
    socket.emit("gameStart")
  }

  const showTeamCard = (color)=>
  {
    return(
      <div className={"teams-"+color+" shadow "+(game.state==="off"?"sized":"")}>

        <h2>{color==="noteam"?"Pas d'équipe":"Équipe"} {color.tr()}</h2>
        <ul>
          {game.players.filter(player=>player.team === color).map((p,k)=>
            {
            return <li key={k} className={player.id===p.id?"you":""}>{true?<><FontAwesomeIcon icon={p.isSpy?faUserSecret:faSearch} /> - </>:null}{p.userName}{p.ready?" (prêt)":""}</li>
            })
            }
        </ul>
        {game.state ==='off'?
          (player.team===color?
            <button onClick={()=>changeReadyPlayer()} className={"btn btn-team btn-"+color}>{player.ready?"Je ne suis pas prêt":"Je suis prêt"}</button>:
            <button onClick={()=>changeTeamPlayer(color,player.isSpy)} className={"btn btn-team btn-"+color}>Rejoindre</button>)
          :null}
      </div>
    )
  }

  const showLobby =() => 
  {
    return(<>
      <div className="game-header">
        <h1>Codenames - <span className={"txt-"+player.team}>{changing?<input id="nameInput" className="name-input" onChange={(e)=>setNewUsername(e.target.value)} value={newUsername} />:player.userName} <FontAwesomeIcon icon={player.isSpy?faUserSecret:faSearch} /></span></h1>
      </div>
      <div className="d-flex">
        <button className="btn btn-success shadow" onClick={()=>changeUsername()}>{changing?"Valider":"Changer mon pseudo"}</button>
        {changing?<button className="btn btn-danger" onClick={()=>changeUsername(true)}>Annuler</button>:null}
        <button className="btn btn-noteam" onClick={()=>changeRolePlayer()}>Devenir {player.isSpy?<>agent - <FontAwesomeIcon icon={faSearch} /></>:<>espion - <FontAwesomeIcon icon={faUserSecret} /></>}</button>
      </div>
      <h2>Pour commencer, choisissez tous une équipe !</h2>
      <div className="teams">  
          {showTeamCard('red')}
          {game.players.filter(player=>player.team=="noteam").length==0?null:showTeamCard('noteam')}
          {showTeamCard('blue')}
      </div>
      <div className="legend">
        <h4><FontAwesomeIcon icon={faUserSecret} /> : Éspion - donne des indices à son équipe pour leur faire deviner les noms de code </h4>
        <h4><FontAwesomeIcon icon={faSearch} /> : Agent - coopère avec son espion et les autres agents pour deviner tous les noms de code</h4>
      </div>
      <h2>{game.players.length<=3?"Il manque "+(4-game.players.length)+" joueurs pour lancer la partie.":"Joueurs prêts : "+game.players.filter(player=>player.ready).length+" / "+game.players.length}</h2>
      
      {game.players.length>=4&&game.players.filter(player=>player.ready).length === game.players.length? <Timer launch={()=>startTheGame()} msg={"La partie commence dans [time] secondes."} startAt={3} isActive={true} />:""}

    </>)
  }

  const showDisconnected = ()=>
  {
    return(
    <>
      <h1>Codenames</h1>
      <h2>Désolé, le serveur n'est actuellement pas en ligne</h2>
    </>)
  }

  const showGame = ()=>
  {
    return(<>
      <div className="game-header">
        <h1>Codenames - <span className={"txt-"+player.team}>{player.userName} <FontAwesomeIcon icon={player.isSpy?faUserSecret:faSearch} /></span></h1>
        <div className="tab-info">
            <div className={"tab-info__bubble tab-info__bubble-"+(winner==="nowinner"?game.teamTurn+(isInit?" init":""):winner)}>
            <h3>{winner==="nowinner"?<>Au tour de{game.teamTurn===player.team?" votre équipe":" l'équipe adverse"}</>:<>Victoire de l'équipe des {winner.tr()+'s'}</>}</h3>
            </div>
        </div>
      </div>
      <div className="flash">
        <div className="flash-content shadow-light">
        <h2 dangerouslySetInnerHTML={{__html: game.flash}} />
        </div>
      </div>
      <div className="board">
        {game.cards.map((card,id)=>
          {
            return(<div onClick={() => cardClick(id)} className={'card' + ' card-'+id + (card.state?' '+card.color:(player.isSpy?' spy-'+card.color:(player.team===game.teamTurn?' clickable':'')))} key={id}>{card.value.toUpperCase()}</div>)
          })
        }
      </div>
      {isOver? <Timer launch={()=>stopTheGame()} msg={"La partie est terminée. Retour au lobby dans [time] secondes."} startAt={10} isActive={true} />:""}
      <div className="tab">
        <div className="tab-red">
          <h2>Équipe rouge - Score: {redScore==-1?game.cards.filter(card=>card.state===1&&card.color==='red').length:redScore}/{game.cards.filter(card=>card.color==='red').length}</h2>
          <ul>
            {game.players.filter(player=>player.team === 'red').map((p,k)=>
              {
              return <li key={k} className={player.id===p.id?"you":""}><FontAwesomeIcon icon={p.isSpy?faUserSecret:faSearch} /> - {p.userName}</li>
              })
              }
          </ul>
        </div>
        <div className="tab-info">
            {player.team==game.teamTurn&&!player.isSpy&&!isOver?<><button className="tab-info__bubble btn-pass" onClick={()=>playerPass()}><h3>Passer ?</h3></button></>:null}
        </div>
        <div className="tab-blue">
          <h2>Équipe bleue - Score: {blueScore==-1?game.cards.filter(card=>card.state===1&&card.color==='blue').length:blueScore}/{game.cards.filter(card=>card.color==='blue').length}</h2>
          <ul>
            {game.players.filter(player=>player.team === 'blue').map(p=>
              {
              return <li className={player.id===p.id?"you":""}><FontAwesomeIcon icon={p.isSpy?faUserSecret:faSearch} /> - {p.userName} </li>
              })
              }
          </ul>
        </div>
      </div>
    </>)
  }

  const changeUsername = (cancel=false)=>
  {
    if(changing)
    {
      if(!cancel && newUsername!=="")
      {
        setPlayer({...player, userName : newUsername})
      }
      setChanging(false)
      setNewUsername("")
    }
    else
    {
      setChanging(true)
    }
  }

  String.prototype.tr = function()
  {
    let tradArray = {
      'blue' : 'bleu',
      'red' : 'rouge',
    }
    return tradArray[this]
  }

  return (
    <div className="main">
      {socket&&socket.connected?(game.state == 'off'? showLobby(): showGame()):showDisconnected()}
    </div>
  );
}

export default App;